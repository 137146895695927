@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
	height: 100%;
	width: 100%;
	font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
	background: #21295c;
}

/* Handle default */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: all 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.react-grid-item.react-grid-placeholder {
	display: none;
}

.full-screen-image-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	/* Darkened background with transparency */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	/* Ensure it's above other content */
}

.full-screen-image {
	position: relative;
}

.full-screen-image img {
	width: 100%;
	max-height: 100%;
	object-fit: cover;
	border-radius: 5px;
	cursor: pointer;
}